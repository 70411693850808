@import '~mdb-ui-kit/css/mdb.min.css';

body {
  margin: 0;
  font-family: "Roboto",sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem !important;
  font-weight: 400;
}
